// package exports here
export {useCookies} from './src/cookies/useCookies'

export * from './src/stage-network/StageNetwork'
        import * as me from '@peachy/utility-kit-browser'
        
        Object.entries(me).forEach(([exportedAs, exported]) => {
            if (exported && Object.isExtensible(exported)) {
                exported[Symbol.for('__WORKSPACE_HOME')] = '/codebuild/output/src3102476736/src/codestar-connections.eu-west-2.amazonaws.com/git-http/011528273016/eu-west-2/ba15a82a-f179-4aff-b6b0-ab0bddfd2d62/peachy-punnet/peachy-mono-repo/utility-kit/browser'
                exported[Symbol.for('__WORKSPACE_NAME')] = '@peachy/utility-kit-browser'
                exported[Symbol.for('__EXPORTED_AS')] = exportedAs
            }
        })
    